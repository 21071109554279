/* You can add global styles to this file, and also import other style files */
:root {
    --bs-blue: rgb(242, 149, 89);
    --bs-indigo: #6610f2;
    --bs-purple: #6f42c1;
    --bs-pink: #d63384;
    --bs-red: #dc3545;
    --bs-orange: #fd7e14;
    --bs-yellow: #ffc107;
    --bs-green: #198754;
    --bs-teal: #20c997;
    --bs-cyan: #0dcaf0;
    --bs-white: rgb(214, 214, 214);
    --bs-gray: #6c757d;
    --bs-gray-dark: #343a40;
    --bs-gray-100: rgb(214, 214, 214);
    --bs-gray-200: rgb(214, 214, 214);
    --bs-gray-300: rgb(214, 214, 214);
    --bs-gray-400: rgb(214, 214, 214);
    --bs-gray-500: rgb(214, 214, 214);
    --bs-gray-600: #6c757d;
    --bs-gray-700: #495057;
    --bs-gray-800: #343a40;
    --bs-gray-900: #212529;
    --bs-primary: rgb(242, 149, 89);
    --bs-secondary: #6c757d;
    --bs-success: #198754;
    --bs-info: #0dcaf0;
    --bs-warning: #ffc107;
    --bs-danger: #dc3545;
    --bs-light: rgb(214, 214, 214);
    --bs-dark: #212529;
    --bs-primary-rgb: 13, 110, 253;
    --bs-secondary-rgb: 108, 117, 125;
    --bs-success-rgb: 25, 135, 84;
    --bs-info-rgb: 13, 202, 240;
    --bs-warning-rgb: 255, 193, 7;
    --bs-danger-rgb: 220, 53, 69;
    --bs-light-rgb: 248, 249, 250;
    --bs-dark-rgb: 33, 37, 41;
    --bs-white-rgb: 255, 255, 255;
    --bs-black-rgb: 0, 0, 0;
    --bs-body-color-rgb: 33, 37, 41;
    --bs-body-bg-rgb: 255, 255, 255;
    --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
    --bs-body-font-family: var(--bs-font-monospace);
    --bs-body-font-size: 1rem;
    --bs-body-font-weight: 400;
    --bs-body-line-height: 1.5;
    --bs-body-color: rgb(214, 214, 214);
    --bs-body-bg: rgb(32, 44, 57);
    --primary-text: rgb(214, 214, 214);
    --secondary-text: rgb(40, 56, 69);
    --border-light: rgb(53, 73, 90);
    --bg-darker: hsl(211, 28%, 17%);
    --bg-dark: hsl(211, 28%, 21%);
    --bg-light1: rgb(184, 176, 141);
    --bg-light2: rgb(207, 192, 189);
    --bg-light3: rgb(242, 212, 146);
    --bg-light4: rgb(242, 149, 89);
    --bs-table-bg: var(--bg-dark);
    --bs-table-striped-bg: var(--bg-darker);
}
tr, thead, th {
    color: var(--primary-text);
    border-color: var(--border-light);
}
.table-hover > tbody > tr:hover > * {
    color: var(--bg-light3) !important;
}
.bg-primary {
    background-color: var(--bg-light2) !important;
    color: var(--secondary-text);
}
.bg-warning {
    background-color: var(--bg-light4) !important;
    color: var(--secondary-text);
}
a, thead th {
    color: var(--bg-light3);
    text-decoration: none;
}
a:hover {
    color: var(--bg-light4);
}
.card {
    background-color: var(--bg-dark);
    border-radius: 14px;
    min-height: 200px;
    justify-content: center;
}
.card-sm {
    min-height: 100px;
    text-align: center;
}
.list-group, .list-group-item {
    background-color: var(--bg-dark);
    color: var(--primary-text);
    border-color: var(--border-light);
}
.ngx-charts .gridline-path {
    stroke: var(--border-light) !important;
}
.progress {
    background-color: var(--bg-darker);
}
.popover_title {
    text-decoration: none;
    text-align: center;
    color: var(--bg-light3);
}
.popover_wrapper {
    position: relative;
    display: inline-block;
}
.popover_content {
    opacity: 0;
    border-radius: 8px;
    visibility: hidden;
    position: absolute;
    left: -20px;
    transform: translate(0, 20px);
    background-color: var(--bg-darker);
    padding: 1rem;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    width: fit-content;
    white-space: nowrap;
}
.popover_content a {
    width: max-content;
    max-width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
}
.popover_wrapper:hover .popover_content {
    z-index: 10;
    opacity: 1;
    visibility: visible;
    transform: translate(0, 0px);
    transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
}
.busy-repo {
    background-color: var(--bg-darker);
    border-radius: 8px;
    min-width: 140px;
    min-height: 160px;
}
.busy-repo a {
    font-size: 0.875rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 110px;
    display: inline-block;
}